.pdf-container {
  max-height: 600px;
  overflow-y: auto;
  width: 50%;
}

.pdf-page {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.app-title {
  font-size: 24px;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.form-input {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form-input input {
  margin-left: 72px;
  margin-top: 10px;
}

.form-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
}

.form-button:hover {
  background-color: #0056b3;
}

.container__pdfs {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.pdf-container {
  margin-top: 20px;
}

.pdf-page {
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  margin-top: 10px;
}

.opacity {
  opacity: 0.5;
  cursor: no-drop;
}
